<template>
  <v-card class="d-flex flex-column mx-auto pb-5 shadow" :class="postClasses" :height="heigth" min-height="300" :width="width || '100%'" max-width="900px"
    @mouseenter="onPostMouseEnter" @mouseleave="onPostMouseLeave"
  >
    <v-card-title v-if="post.title" :class="[ ['orange', 'yellow'].includes(color) ? 'text-purple' : '' ]">{{ post.title }}</v-card-title>
    <v-divider v-if="post.title"></v-divider>
    <v-card-text> {{ post.content.replaceAll(`^`, '\n') }} </v-card-text>
    <div class="post-footer d-flex w-100">
      <span class="created-at mt-auto ml-5" v-if="post.createdAt">{{ `${$t('Created at')}: ${new Date(post.createdAt).toLocaleString([], { dateStyle: 'short' })}` }}</span>
      <v-icon class="ml-auto text-gold" @click="toggleLikeOnPost(post.id)">{{ liked ? "mdi-heart" : "mdi-heart-outline" }}</v-icon>
      <!--<span class="subheading mr-2">11</span>-->
      <span class="mx-3">·</span>
      <v-menu class="share-menu" :close-on-content-click="false" offset-y origin="center center" rounded transition="scale-transition">
        <template v-slot:activator="{ attrs, on }">
          <v-icon v-bind="attrs" v-on="on" class="mr-5 text-gold" @click="shareDialog = true">mdi-share-variant</v-icon>
        </template>
          <v-card class="d-flex flex-row justify-center align-center mx-0 px-7 purple-gradient" height="35" flat color="purple" :hover='false'>
            <ShareNetwork class="share" network="facebook" title="Filosofia e Poesia" url="https://filosofia-e-poesia.web.app" 
              :quote="`''${post.content.replaceAll('^', '')}'' \n\n ${$t('Did you liked it? Access us for more content like this')}`" hashtags="filosofiaepoesia">
              <v-icon color="white">mdi-facebook</v-icon>
            </ShareNetwork>
            <!--<span class="subheading">11</span>-->
          </v-card>
      </v-menu>
    </div>
  </v-card>
</template>
<script>
import { showSnackBar } from '@/services/snackbar'

export default {
  name: 'Post',
  components: {},
  props: {
    post: Object,
    width: String,
    heigth: String,
    color: String,
  },
  data: () => ({
    liked: false,
    postClasses: [],
    shareDialog: false
  }),
  created () {
    this.liked = this.post.liked
    this.postClasses = [`${this.color || 'purple'}-gradient`]
  },
  methods: {
    onPostMouseEnter () { 
      this.postClasses = this.postClasses.filter(postClass => postClass !== `${this.color || 'purple'}-gradient`)
      this.postClasses.push(`${this.color || 'purple'}-dark-gradient`)
    },
    onPostMouseLeave () { 
      this.postClasses = this.postClasses.filter(postClass => postClass !== `${this.color || 'purple'}-dark-gradient`) 
      this.postClasses.push(`${this.color || 'purple'}-gradient`)
    },
    toggleLikeOnPost (postID) {
      if (!this.$store.getters['user/user']) {
        showSnackBar({
          message: 'Sign up to like this post. Thank you!',
          config: {
            color: 'orange',
            timeout: 4000,
          }
        })
        return
      }
      this.liked = !this.liked
      this.$emit('postLikeToggle', { id: postID, title: this.post.title || '', liked: this.liked })
    }
  },
  watch: {
    post: {
      handler(changedPost) { 
        this.liked = changedPost.liked 
      },
      deep: true
    },
    color: {
      handler(changedColor) { 
        this.postClasses = [`${changedColor || 'purple'}-gradient`]
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/_classes.scss';
  @import '@/assets/scss/_animations.scss';
  
  .v-card {
    margin: 0 5px;
    overflow-wrap: unset;
    white-space: unset;
    word-wrap: break-word;
    transition: all .2s ease-in-out;
    
    .share {
      .v-icon {
        &:hover { color: #1877f2 !important; }
      }
    }
  }

  .v-card__title {
    width: 100%;
    margin-left: 5px;
    color: gold;
    text-shadow: 1px 1px 1px black;
    font-size: 24px;
    font-family: 'Pacifico', cursive !important;
    word-wrap: break-word;
  }

  .v-divider {
    width: 90%;
    margin: 0 auto;
    border-width: thin 0 1px 0 !important;
  }

  .v-card__text {
    color: white !important;
    text-shadow: 1px 1px 1px black;
    width: 97% !important;
    margin: 5px auto 10px;
    font-size: 1.3em !important;
    white-space: pre-line;

    @media (max-width: 500px) { font-size: 1.03em !important; }
  }

  .post-footer {
    margin-top: auto;

    .created-at { 
      color: white;
      font-size: 0.87em !important; 
      font-style: italic;
    }

    .share {
      width: 100;
      height: 100;
    }
  }
  
</style>
